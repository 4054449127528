import request from '@/utils/request'

// 查询一览  企业客户
export function listPage(data) {
  return request({
    url: '/company/listPage',
    method: 'post',
     data
  })
}
// 查询详情  企业客户
export function listById(data) {
  return request({
    url: '/company/listById',
    method: 'get',
    params: data
  })
}

// 批量导入企业  企业客户
export function files(data) {
  return request({
    url: '/company/files',
    method: 'post',
    data
  })
}

// 批量设置等级  企业客户
export function setClientGrade(data) {
  return request({
    url: '/company/clientGrade',
    method: 'post',
    data
  })
}
// 客户企业table数据统计  企业客户
export function companyTopListById(data) {
  return request({
    url: '/company/companyTopListById',
    method: 'get',
    params: data
  })
}

// 冻结、解冻  企业客户
export function isFreeze(data) {
  return request({
    url: '/company/isFreeze',
    method: 'get',
    params: data
  })
}

// 新增 企业客户
export function save(data) {
  return request({
    url: '/company/save',
    method: 'post',
    data
  })
}

// 修改 企业客户
export function update(data) {
  return request({
    url: '/company/update',
    method: 'put',
    data
  })
}

// 查询一览  客户经理
export function searchCustomerManager(data) {
  return request({
    url: '/customerManager/companyListPage',
    method: 'get',
    params: data
  })
}

// 查询详情 客户经理
export function searchCustomerManagerById(data) {
  return request({
    url: '/customerManager/listById',
    method: 'get',
    params: data
  })
}

// 批量分配客户经理
export function notAllocate(data) {
  return request({
    url: '/company/notAllocate',
    method: 'post',
    data
  })
}

// 查询一览  联络人
export function searchContactPerson(data) {
  return request({
    url: '/companyLiaisons/listPage',
    method: 'get',
    params: data
  })
}

// 查询详情  联络人
export function searchContactPersonById(data) {
  return request({
    url: '/companyLiaisons/listById',
    method: 'get',
    params: data
  })
}

// 冻结、解冻  联络人
export function notFreezeContactPerson(data) {
  return request({
    url: '/companyLiaisons/notFreeze',
    method: 'get',
    params: data
  })
}

// 新增 联络人
export function saveContactPerson(data) {
  return request({
    url: '/companyLiaisons/save',
    method: 'post',
    data
  })
}

// 修改 联络人
export function updateContactPerson(data) {
  return request({
    url: '/companyLiaisons/update',
    method: 'put',
    data
  })
}

// 查询一览  服务记录
export function searchServiceLog(data) {
  return request({
    url: '/companyServiceLog/listPage',
    method: 'get',
    params: data
  })
}

// 查询详情  服务记录
export function searchServiceLogById(data) {
  return request({
    url: '/companyServiceLog/listById',
    method: 'get',
    params: data
  })
}

// 查询一览  服务建议
export function searchSuggestion(data) {
  return request({
    url: '/serviceSuggestion/listPage',
    method: 'get',
    params: data
  })
}

// 查询详情  服务建议
export function searchSuggestionById(data) {
  return request({
    url: '/serviceSuggestion/listById',
    method: 'get',
    params: data
  })
}

// 查询一览  诊断记录
export function searchDiagnosticLogout(data) {
  return request({
    url: '/diagnosticLogout/listPage',
    method: 'get',
    params: data
  })
}

// 企业股权信息 天眼查
export function listEquityPage(data) {
  return request({
    url: '/companyEye/listEquityPage',
    method: 'get',
    params: data
  })
}

// 企业分公司信息 天眼查
export function listFilialPage(data) {
  return request({
    url: '/companyEye/listFilialPage',
    method: 'get',
    params: data
  })
}

// 企业项目招标信息 天眼查
export function listProjectBiding(data) {
  return request({
    url: '/companyEye/listProjectBiding',
    method: 'get',
    params: data
  })
}

// 企业招聘信息 天眼查
export function listRecruit(data) {
  return request({
    url: '/companyEye/listRecruit',
    method: 'get',
    params: data
  })
}
//客户管理报备
export function companyReport(data) {
  return request({
    url: "/companyGinseng/companyReport",
    method: "post",
    data
  })
}

//需求记录列表
export function selectRequirementPage(data) {
  return request({
    url: "/ipadFirstPage/selectRequirementPage",
    method: "post",
    data
  })
}
//售前建议列表
export function selectPresalesSuggestionPage(data) {
  return request({
    url: "/ipadFirstPage/selectPresalesSuggestionPage",
    method: "post",
    data
  })
}
//企业导图
export function getSceneMapResult(query) {
  return request({
    url: "/ipadFirstPage/getSceneMapResult",
    method: "get",
    params:query
  });
}
//潜在线索列表
export function ipadFirstPage(data) {
  return request({
    url: "/ipadFirstPage/listPage",
    method: "post",
   data
  });
}
  //需求诊断详情
  export function selectRequirementById(query) {
    return request({
      url: "/ipadFirstPage/selectRequirementById",
      method: "get",
      params:query
    });
  }
//潜在线索查看详情
export function cluelistById(query) {
  return request({
    url: "/ipadFirstPage/listById",
    method: "get",
    params:query
  });
}
export function wxCompanysave(data) {
  return request({
    url: "wxCompany/save",
    method: "post",
    data
  })
}
//解除客户经理管理企业权限
export function cancelAllocate(data) {
  return request({
    url: "/company/cancelAllocate",
    method: "post",
    data
  })
}
//国民经济行业标签组
export function getIndustryLabelLists(query) {
  return request({
    url: "/company/getIndustryLabelLists",
    method: "get",
    params:query
  });
}
